.donatorjikontejnert {
  background: linear-gradient(135deg, #17458f, #f7a81b); 
  width: 100%; 
  min-height: 100vh; 
  position: relative;
  padding: 20px 0; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.donatorjieventgallery {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 20px;
  border: solid blue 1px;
  border-radius: 10px;
  box-shadow: 0 0 5px #f7a81b;
  background-color: #ffffff;
}

.donatorjipodnaslov {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.donatorjigallerygrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  width: 100%;
}

.donatorjigalleryitem {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.donatorjiimagecontainer {
  display: block;
  width: 100%;
  height: auto;
}

.donatorjigalleryimage {
  width: 90%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
}

.donatorjioverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.donatorjieventtitle {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.donatorjisp1 {
  border: solid gold 1px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .donatorjieventgallery {
    padding: 10px;
  }

  .donatorjipodnaslov {
    font-size: 1rem;
  }

  .donatorjigallerygrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }

  .donatorjieventtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .donatorjieventgallery {
    padding: 5px;
    width: 320px;
  }

  .donatorjipodnaslov {
    font-size: 0.9rem;
  }

  .donatorjigallerygrid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 5px;
  }

  .donatorjieventtitle {
    font-size: 1rem;
  }
}

.donatorjieventgallery {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.donatorjieventgallery.visible {
  opacity: 1;
  transform: translateY(0);
}