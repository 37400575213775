.castniclanicelkontejner {
  background-image: url('../../Logos/rotaryozadje.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
}

.castniclanikontejner {
  border-radius: 20px;
  padding: 1rem;
  max-width: 1000px;
  width: 90%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.7s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.castniclaniuserlisttitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #17458f;
  font-family: "Arial", sans-serif;
  text-shadow: 0 0 5px rgba(23, 69, 143, 0.6);
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  margin: 20px auto;
}

.castniclaniuserlist {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.castniclaniuserlistitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 1.5rem;
  margin: 0.5rem;
  width: 280px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translateY(30px);
  animation: reveal 0.8s ease forwards;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.castniclaniuserlogo {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
  border: 2px solid #f7a81b;
}

.castniclaniusername {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #17458f;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-family: "Segoe UI", sans-serif;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease, color 0.3s ease;
  border: 2px solid #f7a81b;
}

@media (max-width: 768px) {
  .castniclanikontejner {
    padding: 1.5rem;
    width: 100%;
  }

  .castniclaniuserlistitem {
    width: 220px;
    padding: 1.2rem;
  }

  .castniclaniuserlogo {
    width: 100px;
    height: 100px;
  }

  .castniclaniusername {
    font-size: 1.2rem;
  }

  .castniclaniuserlisttitle {
    font-size: 1.8rem;
  }
}