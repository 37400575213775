.onascelkontejner {
  background-image: url('../Logos/rotaryozadje.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.onassimplelayoutcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.onasimagecontainer {
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
}

.onasimage {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.onastextcontainer {
  max-width: 30%;
  font-family: Arial;
  text-align: left;
  box-shadow: 0 2px 6px #17458f;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}


@media (max-width: 1000px) {
  .onassimplelayoutcontainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
  }

  .onasimagecontainer {
    margin-bottom: 1rem;
    max-width: 90%;
  }

  .onastextcontainer {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .onassimplelayoutcontainer {
    gap: 1rem;
    padding: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .onasimagecontainer {
    width: 100%;
  }

  .onasimage {
    max-width: 100%;
  }

  .onastextcontainer {
    max-width: 100%;
  }
}