.postaniclancelkontejner {
  background-image: url('../../Logos/rotaryozadje.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

.contact-form {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  align-items: center;
  margin: 10px 0;
  border: solid #f7a81b 2px;
}

.contact-form__input,
.contact-form__textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  margin-bottom: 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form__input:focus,
.contact-form__textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.contact-form__textarea {
  resize: vertical;
  min-height: 120px;
}

.contact-form__button {
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
}

.contact-form__button:hover {
  background-color: #0056b3;
}

.contact-form__button:active {
  transform: scale(0.98);
}

.contact-form__status {
  text-align: center;
  font-size: 1rem;
  color: #007bff;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .postaniclancelkontejner {
    padding: 1rem; 
    height: auto;
  }

  .contact-form {
    padding: 1.5rem;
    max-width: 90%;
    margin: 10px 0;
  }

  .contact-form__input,
  .contact-form__textarea,
  .contact-form__button {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .postaniclancelkontejner {
    padding: 1rem;
    height: auto; 
  }

  .contact-form {
    padding: 1rem;
    max-width: 90%;
    margin: 10px 0;
  }

  .contact-form__input,
  .contact-form__textarea {
    padding: 0.5rem;
  }

  .contact-form__button {
    font-size: 0.85rem;
    padding: 0.8rem;
  }

  .contact-form__status {
    font-size: 0.85rem;
  }
}