.dogodkikontejnerd {
  background: linear-gradient(135deg, #17458f, #f7a81b);
  width: 100%;
  min-height: 100dvh; 
  position: relative;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dogodkieventgallery {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}

.dogodkigallerytitle {
  margin-bottom: 2rem;
  font-size: clamp(1.5rem, 2vw, 2rem); 
}

.dogodkigallerygrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.dogodkigalleryitem {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dogodkiimagecontainer {
  position: relative;
  overflow: hidden;
}

.dogodkigalleryimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.dogodkioverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
}

.dogodkigalleryitem:hover .dogodkigalleryimage {
  transform: scale(1.1);
}

.dogodkigalleryitem:hover .dogodkioverlay {
  opacity: 1;
}

.dogodkieventtitle {
  font-size: clamp(1.2rem, 2vw, 1.4rem);
  margin-bottom: 32%;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 10px;
}

.dogodkieventdescription {
  font-size: clamp(0.85rem, 1vw, 1rem);
  line-height: 1.5;
}

.dogodkipodnaslov {
  font-size: clamp(1rem, 1.2vw, 1.3rem);
}

@media (min-width: 769px) {
  .dogodkigallerygrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dogodkigallerytitle {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .dogodkigallerygrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .dogodkigalleryitem {
    margin-bottom: 1rem;
  }

  .dogodkieventtitle {
    font-size: 1.3rem;
  }

  .dogodkieventdescription {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .dogodkipodnaslov {
    text-align: left;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .dogodkigallerytitle {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .dogodkieventtitle {
    font-size: 1.1rem;
    margin-bottom: 35%;
    background-color: white;
    color: black;
    border-radius: 10px;
    padding: 10px;
  }

  .dogodkieventdescription {
    font-size: 0.85rem;
  }

  .dogodkipodnaslov {
    font-size: 0.9rem;
    text-align: center;
  }
}