.marker-icon {
  width: 50px;
  height: 50px;
  background-image: url("../Logos/Camino.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: 2px solid gold;
}

.kontakt-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  padding: 1rem;
  margin: 0 auto;
  background: linear-gradient(135deg, #17458f, #f7a81b);
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 9px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2);
}

.kontakt-left, .kontakt-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 300px;
  margin: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.kontakt-left {
  margin-right: 10px;
}

.kontakt-map {
  width: 100%;
  height: 50vh;
  border-radius: 15px;
  border: solid gold 2px;
}

.kontakt-right {
  background: linear-gradient(135deg, #17458f, #f7a81b);
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kontakt-info-text {
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 1.2rem;
  border-radius: 12px;
  margin: 0.5rem auto;
  font-size: 18px;
}

.kontakt-logo {
  width: 200px;
  height: auto;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.coordinates {
  margin-top: 8px;
  font-size: 1rem;
  color: #ffdd00;
}

@media (max-width: 768px) {
  .kontakt-container {
    flex-direction: column;
    align-items: center;
  }

  .kontakt-left, .kontakt-right {
    width: 100%;
    margin: 0.5rem 0;
  }

  .kontakt-map {
    height: 40vh;
  }
}

@media (max-width: 480px) {
  .kontakt-map {
    height: 30vh;
  }

  .kontakt-info-text {
    padding: 0.8rem;
    font-size: 0.9rem;
  }

  .kontakt-logo {
    width: 150px;
  }

  .coordinates {
    font-size: 0.8rem;
  }
}