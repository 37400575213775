body {
  margin: 0;
  background-color: #ffffff;
}

.rotarygloballogo {
  height: 30px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #17458f;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 5;
  transition: background-color 0.3s ease;
  border-radius: 15px;
  margin-bottom: 5px;
  border:solid 1px #f7a81b;
}

.navbar-logo {
  font-size: 25px;
  font-family: Arial, sans-serif;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
  z-index: 1;
}

.navbar-item {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar-item:hover {
  color: #f7a81b;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 30px;
  z-index: 1001;
}

.navbar-toggle .bar {
  width: 100%;
  height: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border:solid 1px #f7a81b;
}

.navbar-toggle.active .bar:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
}

.navbar-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.active .bar:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

.submenu-container {
  position: relative;
  z-index: 10;
}

.submenu-trigger {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.submenu-arrow {
  margin-left: 0.7rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
  transition: transform 0.3s ease;
}

.submenu-arrow.open {
  transform: rotate(180deg);
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #17458f;
  border: 1px solid #f7a81b;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 165px;
  pointer-events: none;
}

.submenu-container:hover .submenu,
.submenu.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.submenu-item {
  padding: 0.8rem 1.2rem;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu-item:hover {
  background-color: #f7a81b;
  color: #17458f;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }

  .navbar-logo {
    font-size: 20px;
    order: 1;
  }

  .navbar-toggle {
    display: flex;
    order: 2;
  }

  .navbar-links {
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #17458f;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease, pointer-events 0.3s ease;
    border: solid gold 1px;
    border-radius: 20px;
  }

  .navbar-links.active {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-item {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    padding: 13px;
  }

  .navbar-links.active .navbar-item {
    opacity: 1;
    transform: translateY(0);
  }

  .navbar-item:nth-child(1) { transition-delay: 0.1s; }
  .navbar-item:nth-child(2) { transition-delay: 0.2s; }
  .navbar-item:nth-child(3) { transition-delay: 0.3s; }
  .navbar-item:nth-child(4) { transition-delay: 0.4s; }

  .submenu {
    pointer-events: auto;
    z-index: 1003;
  }
}

@media (max-width: 600px) {
  .navbar-links {
    flex-direction: column;
  }

  .navbar-toggle.active + .navbar-links {
    display: flex;
    opacity: 1;
  }
}