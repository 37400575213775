.footer-container {
  color: white;
  padding: var(--footer-padding);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(135deg, #17458f, #f7a81b); 
  border-radius: 15px;
  margin-top: 2px;
  margin-bottom: 3px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
}

.footer-section {
  flex: 1;
  text-align: center;
  margin: 10px;
}

.footer-link {
  color: var(--secondary-color);
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--text-color);
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.3); 
  padding:2px;
  border-radius: 5px;
}

.social-link:hover {
  color: var(--secondary-color);
}

.social-link.facebook {
  color: #4267B2;
}

.social-link.facebook:hover {
  color: #365899;
}

.social-link.youtube {
  color: #FF0000;
}

.social-link.youtube:hover {
  color: #CC0000;
}

.social-icon {
  width: var(--social-icon-size);
  height: var(--social-icon-size);
}

.footer-logo, .pandabit-logo {
  max-width: 90px;
  margin: 10px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 5px;
  }

  .social-icons {
    gap: 10px;
  }
}