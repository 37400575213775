.clanigallerycontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  background: linear-gradient(135deg, #17458f, #f7a81b);
  border-radius: 15px;
}

.clanigalleryitem {
  position: relative;
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 6px #17458f;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: inset 0 0 5px #f7a81b; 
}

.clanigalleryimage-container {
  position: relative;
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-radius: 10px;
}

.clanigalleryimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.misel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00a2e0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (hover: hover) {
  .clanigalleryitem:hover .clanigalleryimage {
    opacity: 0;
  }

  .clanigalleryitem:hover .misel-overlay {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .misel-overlay.visible {
    opacity: 1;
  }

  .misel-overlay.hidden {
    opacity: 0;
  }
}

.clanitext{
  color:#17458f;
}

.clanigallerytext {
  margin-top: 10px;
  font-size: 15px;
  color: #333;
}

.candidate {
  text-align: center;
  margin-bottom: 2rem;
}

.candidate-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.clani-gallery {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.image-container {
  text-align: center;
}

.kandidatislika {
  height: 200px;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.kandidatislika:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.image-caption {
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
  background-color:  #b9d9eb;
  border-radius: 5px;
  padding:5px;
}

.vspomin {
  background-color: #fff;
  border-radius: 15px;
  padding: 2rem;
  max-width: 300px;
  margin: 0 auto;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-bottom: 20px;
}

.spomin-title {
  font-size: 1.8rem;
  color: #17458f;
  margin-bottom: 1rem;
}

.spomin-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.in-memory {
  height: 200px;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 1.5rem;
}

.in-memory:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.vspomincrta{
  border:solid gold 1px;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .clani-gallery {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .candidate-title {
    font-size: 1.4rem;
  }

  .vspomin {
    padding: 1.5rem;
  }

  .spomin-title {
    font-size: 1.5rem;
  }

  .spomin-description {
    font-size: 0.9rem;
  }
}

.clanigallerycontainer {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.clanigallerycontainer.visible {
  opacity: 1;
  transform: translateY(0);
}
