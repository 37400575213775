.dogodkipodstranigallerycontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.dogodkipodstranigallerytitle {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.dogodkipodstranigallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 15px;
  width: 100%;
  max-width: 1200px; 
}

.dogodkipodstranigalleryitem {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.dogodkipodstranigalleryitem:hover {
  transform: scale(1.05);
}

.dogodkipodstranigalleryimage {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.dogodkipodstraniimagetext {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.dogodkipodstranigalleryitem:hover .dogodkipodstraniimagetext {
  opacity: 1;
}

.dogodkimodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.dogodkimodalcontent {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  text-align: center;
  animation: slideUp 0.3s ease-out;
  max-width: 90%;
  max-height: 80vh;
  overflow: auto;
}

.dogodkimodalimage {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  margin-bottom: 15px;
  border-radius: 10px;
}

.dogodkimodaltext {
  font-size: 1.2rem;
  color: #333;
}

.dogodkiclosebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f7a81b;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dogodkiclosebutton:hover {
  background-color: #d32f2f;
}

.dogodkiarrow-left,
.dogodkiarrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  color: #f7a81b;
  background-color: transparent;
  border: none;
  padding: 1px 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 10;
  border-radius: 5%; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.dogodkiarrow-left {
  left: 10px;
}

.dogodkiarrow-right {
  right: 10px;
}

.dogodkiarrow-left:hover,
.dogodkiarrow-right:hover {
  transform: translateY(-50%) scale(1.1);
}

.dogodkiarrow-left:focus,
.dogodkiarrow-right:focus {
  outline: none;
}

.pivovarnavizirlink{
  height: 90px;
  border-radius: 10px;
}

.pivovarnavizirlink:hover{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .dogodkipodstranigallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

