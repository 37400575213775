.donacijecelkontejner {
  background-image: url('../../Logos/rotaryozadje.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 574px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 0;
  box-sizing: border-box;
}

.donation-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  border: solid #f7a81b 2px;
  opacity: 0;
  transform: translateY(50px) scale(0.9);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.donation-container.reveal {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.donation-title {
  font-size: clamp(18px, 3vw, 25px);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
}

.donation-container.reveal .donation-title {
  opacity: 1;
  transform: translateY(0);
}

.donation-display {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #17458f;
  color: #f7a81b;
  font-size: clamp(2.5rem, 6vw, 4rem); 
  font-weight: bold;
  padding: 1.2rem 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 20px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
}

.donation-container.reveal .donation-display {
  opacity: 1;
  transform: scale(1);
}

.donation-display:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

.donation-symbol {
  font-size: clamp(1.2rem, 2vw, 2rem);
  margin-right: 0.5rem;
  position: relative;
  top: -5px;
  opacity: 0.8;
}

.donation-amount {
  font-size: clamp(2.5rem, 6vw, 4rem); 
  letter-spacing: 3px;
  font-weight: bold;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3), 0 0 30px rgba(255, 255, 255, 0.5);
  animation: pulse 5.5s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@media (max-width: 768px) {
  .donation-container {
    max-width: 95%;
    padding: 1.5rem;
  }

  .donation-display {
    font-size: clamp(2rem, 5vw, 3rem);
    padding: 1rem 2rem;
  }

  .donation-symbol {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }

  .donation-amount {
    font-size: clamp(2rem, 5vw, 3rem);
  }
}

@media (max-width: 480px) {
  .donation-container {
    padding: 1.2rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .donation-title {
    font-size: clamp(16px, 4vw, 20px);
  }

  .donation-display {
    font-size: clamp(1.8rem, 4.5vw, 2.5rem);
    padding: 0.8rem 1.5rem;
  }

  .donation-symbol {
    font-size: clamp(1rem, 3vw, 1.2rem);
  }

  .donation-amount {
    font-size: clamp(1.8rem, 4.5vw, 2.5rem);
  }
}