
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color:  #ffffff;
  }
  
  .headerlogo {
      height: 190px;
  }

    @media (max-width: 768px) {
        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1rem;
            text-align: center;
          }
          
          .headerlogo {
            height: 30vw;
            margin-bottom: 1px;
          }
    }