.domovcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 3rem;
  background: linear-gradient(135deg, #17458f, #f7a81b); 
  animation: slideIn 1s ease-out; 
  border-radius: 15px; 
}

.backgroundd-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover; 
  filter: brightness(20%); 
  border-radius: 15px;
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.domovimagegallery {
  width: 800px;   
  height: 405px;  
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); 
}

@keyframes slideInReveal {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.domovcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 3rem;
  background: linear-gradient(135deg, #17458f, #f7a81b);
  border-radius: 15px;
  animation: slideInReveal 1s ease-out; 
}

.domovgalleryimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
  opacity: 0;
  transform: translateY(50px);
  animation: slideInReveal 1.5s ease-out forwards; 
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  color: #f7a81b;
  cursor: pointer;
  z-index: 1;
  user-select: none;
}

.arrow.left {
  left: 30px;
}

.arrow.right {
  right: 30px;
}

.domovtextcontent {
  width: 35%;
  font-family: Arial;
  text-align: left;
  box-shadow: 0 2px 6px #17458f;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 60px;
}

.domovtextscrollcontainer {
  width: 98%;
  height: 110px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #17458f;
  font-family: Arial;
  font-size: 16px;
  border-radius: 10px;
}

.domovtextuvod{
  line-height: 1.5;
}

.domovtextscrollcontainer::-webkit-scrollbar {
  width: 5px;
}

.domovtextscrollcontainer::-webkit-scrollbar-thumb {
  background-color: #17458f;
  border-radius: 10px;
}

.social-media-item a {
  text-decoration: none; 
  color: inherit; 
  display: flex;
  align-items: center; 
  gap: 5px; 
}

.social-icon {
  width: 24px; 
  height: auto;
}

.social-icon:hover {
  transform: scale(1.2);
}

.loading-bar-container {
  position: absolute;
  bottom: 10px;
  width: 90%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  border-radius: 3px;
  left: 50%; 
  transform: translateX(-50%); 
}

.loading-bar {
  height: 100%;
  background-color: #ff9800; 
  width: 0%;  
  transition: none; 
}

.domovsmallimagegallery {
  overflow: auto;
  white-space: nowrap;
  padding: 1px;
  width:798px;
  margin-bottom: 30px;
}

.domovsmallgalleryimage {
  width: 200px; 
  flex: 0 0 auto; 
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); 
}

.domovsmallgalleryimage:hover{
  cursor: pointer;
  box-shadow: 0 2px 2px #17458f; 
}

.domovhimnas1{
  height: 40px;
  margin-top: -5px;
  position: absolute;
  margin-left: 10px;
}

.domovhimnagumb{
  background: linear-gradient(135deg, #17458f, #f7a81b); 
  color:#ffff;
  border-radius: 5px;
  padding:7px;
  margin-right: 5px;
  transition: 0.3s;
  box-shadow: 0px 0px 10px #00eaff;
}

.domovhimnagumb:hover {
  background: linear-gradient(90deg, #ff00ff, #00eaff);
  box-shadow: 0px 0px 15px #ff00ff;
}

@media (min-width: 1025px) and (max-width: 1330px) {

  .domovdomovcontainer {
    gap: 2rem;
  }


  .domovcontainer {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .domovimagegallery {
    width: 100%;
    height: 300px;
  }

  .domovtextcontent {
    width: 90%;
    text-align: left;
  }

  .domovsmallgalleryimage {
    width: 200px;
    height: 100px;
    margin-bottom: 20px;
  }

  .loading-bar-container {
    width: 80%;
  }

  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .domovcontainer {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .domovimagegallery {
    width: 100%;
    height: 300px;
  }

  .domovsmallimagegallery {
    overflow: auto;
    white-space: nowrap;
    padding: 1px;
    width: 80vw; 
    margin-bottom: 30px;
  }

  .domovtextcontent {
    width: 90%;
    text-align: left;
  }

  .domovsmallgalleryimage {
    width: 200px;
    height: 100px;
    margin-bottom: 20px;
  }
  .loading-bar-container {
    width: 80%;
  }

  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
}

@media (max-width: 768px) {
  .domovtextcontent {
    width: 100%;
  }

  .domovtextscrollcontainer {
    width: 97%;
  }

  .domovsmallimagegallery {
    overflow: auto;
    white-space: nowrap;
    padding: 1px;
    width: 95vw; 
    margin-bottom: 30px;
  }
  
  .domovsmallgalleryimage {
    width: 27vw;  
    height: auto;
    flex: 0 0 auto; 
    margin-top: 10px;
  }
  .loading-bar-container {
    width: 80%;
  }

  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .domovcontainer {
    padding: 15px;
    gap: 1rem;
  }

  .domovimagegallery {
    height: 250px;
    border-radius: 15px;
  }

  .domovtextcontent {
    width: 100%;
    font-size: 16px;
    padding: 8px;
  }

  .domovtextscrollcontainer {
    width: 98%;
    height: 90px;
    font-size: 14px;
  }

  .domovsmallimagegallery {
    overflow: auto;
    white-space: nowrap;
    padding: 1px;
    margin-bottom: 20px;
    width: 95vw; 
}
  
  .domovsmallgalleryimage {
    width: 29vw; 
    flex: 0 0 auto; 
    margin-top: 10px;
    padding: 1px;
  }
  .loading-bar-container {
    width: 90%;
  }

  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }

  .backgroundd-image {
    object-fit: fill;
    object-position: center;
    transform: translate(-50%, -50%);
  }
}


@keyframes spinRightLoop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-active {
  animation: spinRightLoop 20s linear infinite; 
}

.domovdflex2-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 60%;
  padding: 10px;
  margin: 0 auto;
  max-width: 90vw;
  min-width: 30vw;
}

.domovdflex2-text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-width: 50%;
  padding-left: 10%;
}

.domovdflex2-text {
  font-size: 1.2rem;
  margin: 5px 0;
  word-wrap: break-word;
  color:white;
}

.domovdtext2-container {
  width: 200px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.domovdflex2-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  animation: spin 30s linear infinite;
}

.domovdflex3-text{
  color:gold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 950px) {
  .domovdflex2-container {
    flex-direction: column;
    max-width: 90vw;
    width: 100%;
  }

  .domovdtext2-container {
    order: -1;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .domovdflex2-text-container {
    width: 90%;
    align-items: flex-start;
    text-align: left;
    padding-left: 5%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.domovgold-text {
  font-family: "Merriweather", serif;
  font-size: 21px;
  color: #d4a017; 
  display: inline-block;
  position: relative;
  background-size: 400% 400%;
  border-bottom:solid gold 1px;
  border-radius: 10px;
  
  text-shadow: 
  1px 1px 1px #8b6508,
  -1px -1px 1px #f7a81b,
  1px -1px 1px #f7a81b,
}

.domovspodenkontejner {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.domovspodenkontejner.visible {
  opacity: 1;
  transform: translateY(0);
}


/*NOVA KODA*/

.domovspodenefekt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  opacity: 0;
  transform: translateY(50px) scale(0.9);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  will-change: opacity, transform;
  margin-top: 50px;
}

.domovspodenefekt.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.efekt-image {
  width: 140px;
  height: 130px;
  border-radius: 15px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
  display: inline-block; 
}

.efekt-image:hover {
  transform: perspective(1000px) rotateY(20deg) rotateX(10deg) scale(1.1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.4);
}

.efekt-image:active {
  transform: perspective(1000px) rotateY(0deg) rotateX(0deg) scale(0.95);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}