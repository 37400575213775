.predsednikicelkontejner{
  background-image: url('../../Logos/rotaryozadje.jpg');
  background-size: cover;   
  background-position: center;   
  background-repeat: no-repeat;    
  display: flex;
}

.predsednikiscrollcontainer {
  width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
}

.predsednikiuserlisttitle {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  padding: 10px;
}

.predsednikiuserlist {
  list-style-type: none; 
  padding: 0;
  margin: 0;
}

.predsednikiuserlistitem {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  padding: 10px;
  text-align: left;
  border-radius: 5px;
}

.predsednikiuserlistitem:hover {
  transform: translateY(-2px); 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
}

.predsednikiusername {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  flex: 2; 
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); 
}

.predsednikiuserdate {
  font-size: 0.9rem;
  color: #888;
  flex: 1;
  text-align: left; 
}

@media (max-width: 500px) {
  .predsednikiscrollcontainer {
    width: 310px;
  }
}
